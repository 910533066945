import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useForm, useFormState, v } from '@ci/forms';
import { Button } from '@ci/atoms';
import { CountdownButton, Grid } from '@myci/ui-components';
import { IntlProvider, Message } from '@myci/intl';
import { resetPassword, selectIsFetchingResetPassword } from '@myci/authentication';
import { Buttons } from '@ci/layout';
import { Link as IntlLink } from '@myci/gatsby-plugin-intl';

import m from '../messages';
import { forms } from '../constants';
import { UserNameField } from './UserNameField';

interface ResetPasswordFormValues {
	username: string;
}
export const validate = v.validate<ResetPasswordFormValues>({
	username: [v.required()],
});

export const ResetPasswordForm = () => {
	const dispatch = useDispatch();
	const isSubmitting = useSelector(selectIsFetchingResetPassword);

	const handleResetPasswordClick = useCallback(
		values => {
			dispatch(resetPassword({ ...values, form: forms.resetPassword }));
		},
		[dispatch]
	);
	const form = useForm({
		id: forms.resetPassword,
		onSubmit: handleResetPasswordClick,
		validate,
	});

	const formState = useFormState({ control: form.control });
	const { isValid } = formState;

	return (
		<IntlProvider>
			<Form form={form}>
				<Grid container flexDirection="column" justifyContent="between">
					<Grid row>
						<Grid col={{ xs: 12, md: 6, lg: 5 }}>
							<UserNameField name="username" isDisabled={isSubmitting} />
							<Buttons
								verticalMargin="lg"
								buttons={[
									<IntlLink to="/sign-in">
										<Button variant="subtle">
											<Message {...m.cancelButton} />
										</Button>
									</IntlLink>,
									<CountdownButton type="submit" isDisabled={isSubmitting || !isValid}>
										<Message {...m.resetButton} />
									</CountdownButton>,
								]}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Form>
		</IntlProvider>
	);
};
